import store from '../store/index.js'

// create request data
export function createRequestData(endpoint, method) {
  const baseUrl = process.env.VUE_APP_TENANT
  return {
    params: {
      url: baseUrl + endpoint,
      method: method,
    },
    cookies: store.state?.cookies,
  }
}

export function createRequestDatandXTAL(endpoint, method) {
  let baseUrl = process.env.VUE_APP_TENANT
  baseUrl = baseUrl.replace(/v2$/, '');
  return {
    params: {
      url: baseUrl + endpoint,
      method: method,
    },
    cookies: store.state?.cookies,
  }
}


// some store ids have spaces in them. replace them with %20
export function replaceSpace(string) {
  return string.replace(' ', '%20')
}

// api url
export const url = process.env.VUE_APP_BACKEND
