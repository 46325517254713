<template>
  <div
    @click.self="closeModal"
    class="modalContainer"
    data-cy="login-modal"
    ref="modal"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
    aria-hidden="false"
  >
    <transition name="scale">
      <div id="modalWrapper" class="modalWrapper">
        <div
          @click="closeModal"
          class="close"
          data-cy="close-login-modal"
          tabindex="0"
          aria-label="Close modal"
          role="button"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.1161 6.6228L7.75908 6.97635L8.11437 7.33163L13.4572 12.6745L12.6745 13.4572L11.8466 12.6294L11.8449 12.6277L7.37718 8.11608L7.02364 7.75906L6.66835 8.11435L1.32551 13.4572L0.542786 12.6745L5.88563 7.33163L6.24062 6.97664L5.8842 6.62309L0.543158 1.32514L1.32551 0.542787L6.66835 5.88563L7.02334 6.24061L7.37689 5.88419L12.6748 0.543155L13.4572 1.32551L12.6294 2.15333L12.6277 2.15504L8.1161 6.6228ZM12.7648 13.5475C12.7649 13.5476 12.765 13.5478 12.7651 13.5479L12.7648 13.5475ZM0.452113 12.7651L0.452145 12.7651C0.452134 12.7651 0.452123 12.7651 0.452113 12.7651Z"
              fill="black"
              stroke="black"
            />
          </svg>
        </div>
        <img class="logo" :src="getMenuLogo" alt="companyLogo" />
        <h2 style="text-align: center">Login To Your Account</h2>
        <div class="signUp">
          <p v-if="branding?.email_sign_in">Don't have an account?</p>
          <a v-if="branding?.email_sign_in" @click="signUpClicked" href="javascript:;" data-cy="create-account-link">
            CREATE AN ACCOUNT
          </a>
        </div>
        <div class="inputWrapper" v-if="branding?.email_sign_in">
          <label for="email">Email</label>
          <input
            @keyup.enter="login"
            v-model="loginInfo.email"
            type="email"
            placeholder="Your Email Address"
            id="email"
            inputmode="email"
            data-cy="email-input"
          />
          <p v-if="v$.loginInfo.email.$error === true" class="validation" data-cy="email-validation-error">
            Please enter your email address
          </p>
        </div>
        <div class="inputWrapper" v-if="branding?.email_sign_in">
          <label for="password">Password</label>
          <input
            @keyup.enter="login"
            v-model="loginInfo.password"
            :type="passwordInputType"
            placeholder="Your Password"
            id="password"
            data-cy="password-input"
          />
          <p v-if="v$.loginInfo.password.$error === true" class="validation" data-cy="password-validation-error">
            Please enter your password
          </p>
          <a @click="togglePassword" class="passwordToggle" href="#" data-cy="toggle-password-visibility">
            <img :src="passwordIconPath" alt="password" />
          </a>
        </div>
        <a
          @click="forgotPasswordClicked"
          class="forgotPassword"
          href="javascript:;"
          data-cy="forgot-password-link"
          v-if="branding?.email_sign_in"
        >
          FORGOT PASSWORD?
        </a>
        <p class="feedback error" v-if="error.status" data-cy="error-message">{{ error.message }}</p>
        <button
          v-if="branding?.email_sign_in"
          @click="login"
          :disabled="buttonLoader"
          data-cy="login-button"
          style="margin-top: 20px"
        >
          <div v-if="buttonLoader" class="loader buttonLoader"></div>
          <template v-if="!buttonLoader">LOGIN</template>
        </button>
        <div
          v-if="branding?.sso?.google?.enabled || branding?.sso?.apple?.enabled || branding?.sso?.okta?.enabled"
          class="seperator"
        >
          <div v-if="branding?.email_sign_in" class="line"></div>
          <p v-if="branding?.email_sign_in">OR</p>
          <div v-if="branding?.email_sign_in" class="line"></div>
        </div>
        <div
          v-if="
            branding?.sso?.google?.enabled ||
            branding?.sso?.apple?.enabled ||
            (branding?.sso?.okta?.enabled && checkAppleSSOAvailable)
          "
          class="ssoButtons"
          :style="{ marginTop: branding?.email_sign_in ? '20px' : '0' }"
        >
          <GoogleSSO
            v-if="branding?.sso?.google?.enabled"
            @getCustomerInfo="getCustomerInfo"
            @ssoError="ssoError"
            @showLoader="showLoader"
            id="googleButton"
          />
          <AppleSSO
            v-if="branding?.sso?.apple?.enabled && checkAppleSSOAvailable"
            @getCustomerInfo="getCustomerInfo"
            @ssoError="ssoError"
            @showLoader="showLoader"
          />
          <OktaSSO
            v-if="branding?.sso?.okta?.enabled"
            @getCustomerInfo="getCustomerInfo"
            @ssoError="ssoError"
            @showLoader="showLoader"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import { mapState, mapMutations } from 'vuex'

import modalMixins from '@/mixins/modalMixins.js'
import fullStoryMixins from '@/mixins/fullStoryMixins'

import customersApi from '@/api/customersApi.js'

import GoogleSSO from '@/components/Elements/GoogleSSO.vue'
import AppleSSO from '@/components/Elements/AppleSSO.vue'
import OktaSSO from '@/components/Elements/OktaSSO.vue'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      loginInfo: {
        email: '',
        password: '',
      },
      passwordInputType: 'password',
      passwordIconPath: require('@/assets/icons/passwordShow.svg'),
      error: {
        status: false,
        message: '',
      },
      googleButtonWidth: null,
    }
  },
  validations() {
    return {
      loginInfo: {
        email: { required, email },
        password: { required },
      },
    }
  },
  components: {
    GoogleSSO,
    AppleSSO,
    OktaSSO,
  },
  mixins: [modalMixins, fullStoryMixins],
  computed: {
    ...mapState(['buttonLoader', 'branding', 'cookies', 'copperDetails']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
    checkAppleSSOAvailable() {
      if (!window.AppleID) return false
      return true
    },
  },
  methods: {
    ...mapMutations([
      'SET_LOGGED_IN',
      'SET_BUTTON_LOADER',
      'SET_CUSTOMER_DATA',
      'SET_TOASTER',
      'SET_PHONE_VERIFIED',
      'SET_COOKIES',
    ]),
    toggleCopperInfo() {
      this.$emit('toggleCopperInfo')
    },
    ssoError() {
      this.error.status = true
      this.error.message = 'Something went wrong. Please try again'
      this.SET_BUTTON_LOADER(false)
    },
    showLoader() {
      this.SET_BUTTON_LOADER(true)
    },
    login() {
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }
      this.error.status = false
      this.error.message = ''
      this.SET_BUTTON_LOADER(true)

      let payload = {
        username: this.loginInfo.email,
        password: this.loginInfo.password,
      }

      customersApi
        .login(payload)
        .then((response) => {
          localStorage.setItem('customerId', response.data.data.customer_id)
          localStorage.setItem('auth', 'Basic ' + response.data.data.auth_cookie)
          this.SET_COOKIES(response.data.cookies)
          this.getCustomerInfo()
        })
        .catch((error) => {
          this.SET_BUTTON_LOADER(false)
          console.log(error)
          this.error.status = true
          if (error.response.data.data && error.response.data.data.error) {
            let errorMessage = error.response.data.data.error
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
            this.error.message = errorMessage
          } else {
            this.error.message = 'Something went wrong. Please try again'
          }
        })
    },
    async getCustomerInfo() {
      this.SET_BUTTON_LOADER(true)
      try {
        const response = await customersApi.getCustomerInfo()
        let toasterData = {
          show: true,
          message: 'Welcome back',
        }
        this.SET_TOASTER(toasterData)
        this.SET_CUSTOMER_DATA(response.data.data)
        // for FullStory
        this.identifyUser(response.data.data)
        this.SET_PHONE_VERIFIED(response.data.data.is_phone_verified)
        localStorage.setItem('phoneVerified', response.data.data.is_phone_verified)
        this.SET_LOGGED_IN(true)
        if (this.copperDetails) {
          this.toggleCopperInfo()
        }
        this.closeModal()
        this.SET_BUTTON_LOADER(false)
        this.SET_COOKIES(response.data.cookies)
      } catch (error) {
        console.log(error)
        this.loading = false
        this.error.status = true
        this.error.message = 'Something went wrong. Please try again'
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
    signUpClicked() {
      this.$emit('closeModal')
      this.$emit('signUpClicked')
    },
    forgotPasswordClicked() {
      this.$emit('closeModal')
      this.$emit('forgotPasswordClicked')
    },
    togglePassword() {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text'
        this.passwordIconPath = require('@/assets/icons/passwordHide.svg')
      } else {
        this.passwordInputType = 'password'
        this.passwordIconPath = require('@/assets/icons/passwordShow.svg')
      }
    },
    trapFocus(event) {
      if (event.key === 'Tab') {
        const modal = this.$refs.modal
        if (!modal) {
          return
        }

        const focusableElements = modal.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
        const firstElement = focusableElements[0]
        const lastElement = focusableElements[focusableElements.length - 1]

        if (event.shiftKey) {
          // Backward tab
          if (document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          }
        } else {
          // Forward tab
          if (document.activeElement === lastElement) {
            event.preventDefault()
            firstElement.focus()
          }
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('email').focus()
    })
    this.$refs.modal.focus()
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', this.trapFocus)
  },
  beforeUnmount() {
    document.body.style.overflow = ''
    document.removeEventListener('keydown', this.trapFocus)
  },
}
</script>

<style src="../../../assets/css/Modals/AuthenticationModals/LoginModal.css" scoped></style>
