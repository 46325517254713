<template>
  <div class="toasterWrapper">
    <TransitionGroup name="toaster">
      <div
        v-for="toaster in toaster"
        :key="toaster"
        class="toaster"
        :style="{ backgroundColor: branding.dark_mode_enabled ? 'var(--primary-color)' : 'var(--text-color)' }"
      >
        <p aria-live="polite" aria-atomic="true" :style="{ color: branding.dark_mode_enabled ? 'var(--primary-text-color)' : '#FFFFFF' }">
          {{ toaster.message }}
        </p>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ToasterView',
  computed: {
    ...mapState(['toaster', 'branding']),
  },
}
</script>

<style src="../../assets/css/Toaster/ToasterView.css" scoped></style>
