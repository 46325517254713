<template>
  <div @click.self="closeModal" class="modalContainer">
    <div class="modalWrapper">
      <div @click="closeModal" class="close" tabindex="0" aria-label="Close modal" role="button">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1161 6.6228L7.75908 6.97635L8.11437 7.33163L13.4572 12.6745L12.6745 13.4572L11.8466 12.6294L11.8449 12.6277L7.37718 8.11608L7.02364 7.75906L6.66835 8.11435L1.32551 13.4572L0.542786 12.6745L5.88563 7.33163L6.24062 6.97664L5.8842 6.62309L0.543158 1.32514L1.32551 0.542787L6.66835 5.88563L7.02334 6.24061L7.37689 5.88419L12.6748 0.543155L13.4572 1.32551L12.6294 2.15333L12.6277 2.15504L8.1161 6.6228ZM12.7648 13.5475C12.7649 13.5476 12.765 13.5478 12.7651 13.5479L12.7648 13.5475ZM0.452113 12.7651L0.452145 12.7651C0.452134 12.7651 0.452123 12.7651 0.452113 12.7651Z"
            fill="black"
            stroke="black"
          />
        </svg>
      </div>
      <h2>Verify Email</h2>
      <p class="description">
        Please enter the PIN sent to
        <span class="bold">{{ customerData.email }}</span>
        below to validate your account.
      </p>
      <div class="pin">
        <Verification
          type="text"
          activeInputClass="active-input-class"
          @on-complete="onCompleteHandler"
          @on-paste="onPasteHandler"
        />
      </div>
      <p v-if="!codeIsValid" class="validation">Invalid pin code</p>
      <a @click="sendVerificationCode(true)" class="resend" href="javascript:;">
        <p>RESEND PIN</p>
      </a>
      <p class="feedback error" v-if="error.status">
        {{ error.message }}
      </p>
      <button class="verify" @click="verifyEmail" :disabled="pinLoading">
        <div v-if="pinLoading" class="loader buttonLoader"></div>
        <template v-if="!pinLoading"> VERIFY </template>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import useVuelidate from '@vuelidate/core'

import formattingMixins from '@/mixins/formattingMixins'

import Verification from './Verification.vue'

import modalMixins from '@/mixins/modalMixins.js'

import customersApi from '@/api/customersApi.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      pin: '',
      codeIsValid: true,
      error: {
        status: false,
        message: '',
      },
      pinLoading: false,
    }
  },
  components: {
    Verification,
  },
  mixins: [modalMixins, formattingMixins],
  computed: {
    ...mapState(['customerData', 'buttonLoader', 'cookies', 'branding']),
  },
  methods: {
    ...mapMutations(['SET_TOASTER', 'SET_CUSTOMER_DATA', 'SET_EMAIL_VERIFIED', 'SET_COOKIES']),
    closeModal() {
      this.$emit('closeModal')
    },
    onCompleteHandler(e) {
      this.pin = e
    },
    onPasteHandler(e) {
      this.pin = e
    },

    sendVerificationCode(resend) {
      let payload = {
        email: this.customerData.email,
      }
      customersApi
        .sendVerificationCodeEmail(payload)
        .then((response) => {
          if (response.data.data.ok) {
            if (resend) {
              this.pinLoading = false
              this.error.message = 'Verification PIN sent again'
            }
          }
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
          this.pinLoading = false
          this.error.message = error.response.data.data.error
        })
    },

    verifyEmail() {
      if (this.pin.length != 6) {
        this.codeIsValid = false
        return
      }

      this.pinLoading = true
      this.error.status = false
      this.error.message = ''

      let payload = {
        email: this.customerData.email,
        security_code: this.pin,
      }

      customersApi
        .verifyEmail(payload)
        .then((response) => {
          if (response.data.data.ok) {
            this.SET_EMAIL_VERIFIED(true)
            localStorage.setItem('emailVerified', true)
            this.getCustomerInfo()
          } else if (!response.data.data.ok) {
            this.pinLoading = false
            this.error.status = true
            this.error.message = 'Verification failed. Please try again.'
          }
          this.SET_COOKIES(response.data.cookies)
        })

        .catch((error) => {
          this.pinLoading = false
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },

    getCustomerInfo() {
      customersApi
        .getCustomerInfo()
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          this.pinLoading = false
          this.$emit('closeModal')
          let toasterData = {
            show: true,
            message: 'Email verified',
          }
          this.SET_TOASTER(toasterData)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.pinLoading = false
          this.loading = false
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
  },
  created() {
    this.sendVerificationCode()
  },
}
</script>

<style src="../../../assets/css/Modals/AuthenticationModals/PhoneVerificationModal.css" scoped></style>
