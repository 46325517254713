<template>
  <div id="appleid-signin" class="signinButton" data-color="white" data-border="false" data-type="continue"></div>
</template>

<script>
import customersApi from '@/api/customersApi.js'

import { mapState, mapMutations } from 'vuex'

export default {
  emits: ['getCustomerInfo'],
  computed: {
    ...mapState(['branding']),
  },
  methods: {
    ...mapMutations(['SET_COOKIES']),
    handleSuccess(response) {
      this.$emit('showLoader')
      let payload = {
        provider: 'apple',
        auth_info: {
          access_token: response.detail.authorization?.id_token,
          first_name: response.detail.user?.name?.firstName,
          last_name: response.detail.user?.name?.lastName,
        },
      }

      if (!response.detail.user?.name?.firstName) {
        delete payload.auth_info.first_name
      }
      if (!response.detail.user?.name?.lastName) {
        delete payload.auth_info.last_name
      }

      customersApi
        .foreignAuth(payload)
        .then((response) => {
          localStorage.setItem('customerId', response.data.data.customer_id)
          this.SET_COOKIES(response.data.cookies)
          this.$emit('getCustomerInfo')
        })
        .catch((error) => {
          console.log(error)
          this.$emit('ssoError')
        })
    },
  },
  mounted() {
    setTimeout(() => {
      /* global AppleID */
      AppleID.auth.init({
        clientId: this.branding?.sso?.apple?.client_id,
        redirectURI: this.branding?.sso?.apple?.return_url,
        scope: 'name email',
        usePopup: true,
      })
    }, 500)

    document.addEventListener('AppleIDSignInOnSuccess', this.handleSuccess)
  },
}
</script>

<style src="../../assets/css/Elements/AppleSSO.css" scoped></style>
