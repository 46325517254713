import { createRouter, createWebHistory } from 'vue-router'
import { LoginCallback } from '@okta/okta-vue'

const HomeView = () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
const MenuView = () => import(/* webpackChunkName: "menu" */ '../views/MenuView.vue')
const AccountView = () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue')
const CheckoutView = () => import(/* webpackChunkName: "checkout" */ '../views/CheckoutView.vue')
const ConfirmationView = () => import(/* webpackChunkName: "confirmation" */ '../views/ConfirmationView.vue')
const ContentHubView = () => import(/* webpackChunkName: "content_hub" */ '../views/ContentHubView.vue')
const PasswordResetView = () => import(/* webpackChunkName: "password_reset" */ '../views/PasswordResetView.vue')
const OrderQuoteView = () => import(/* webpackChunkName: "password_reset" */ '../views/OrderQuoteView.vue')
const CheckInView = () => import(/* webpackChunkName: "password_reset" */ '../views/CheckInView.vue')
const NotFoundView = () => import(/* webpackChunkName: "not_found" */ '../views/Error/NotFoundView.vue')
const LoginView = () => import(/* webpackChunkName: "guest_checkout" */ '../views/LoginView.vue')

const routes = [
  { path: '/login/callback', component: LoginCallback },
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/:storeId/:storeName?',
    name: 'MenuView',
    component: MenuView,
  },
  {
    path: '/account',
    name: 'AccountView',
    component: AccountView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'profile',
        name: 'ProfileView',
        component: () => import(/* webpackChunkName: "profile" */ '../components/Main/ProfilePages/ProfileView.vue'),
      },
      {
        path: 'addresses',
        name: 'AddressesView',
        component: () =>
          import(/* webpackChunkName: "addresses" */ '../components/Main/ProfilePages/AddressesView.vue'),
      },
      {
        path: 'order-history',
        name: 'OrderHistoryView',
        component: () =>
          import(/* webpackChunkName: "orders" */ '../components/Main/ProfilePages/OrderHistoryView.vue'),
      },
      // {
      //   path: 'preferences',
      //   name: 'PreferencesView',
      //   component: () =>
      //     import(/* webpackChunkName: "orders" */ '../components/Main/ProfilePages/PreferencesView.vue'),
      // },
      {
        path: 'external-links',
        name: 'ExternalLinksView',
        component: () =>
          import(/* webpackChunkName: "orders" */ '../components/Main/ProfilePages/ExternalLinksView.vue'),
      },
      {
        path: 'manage-cards',
        name: 'ManageCardsView',
        component: () => import(/* webpackChunkName: "cards" */ '../components/Main/ProfilePages/ManageCardsView.vue'),
      },
      {
        path: 'gift-cards',
        name: 'GiftCardsView',
        component: () =>
          import(/* webpackChunkName: "gift_cards" */ '../components/Main/ProfilePages/GiftCardsView.vue'),
      },
      {
        path: 'rewards',
        name: 'RewardsView',
        component: () => import(/* webpackChunkName: "rewards" */ '../components/Main/ProfilePages/RewardsView.vue'),
      },
      {
        path: 'favorite-items',
        name: 'FavoriteItemsView',
        component: () =>
          import(/* webpackChunkName: "favorites" */ '../components/Main/ProfilePages/FavoriteItemsView.vue'),
      },
    ],
  },
  {
    path: '/:storeId/:storeName?/checkout',
    name: 'CheckoutView',
    component: CheckoutView,
  },
  {
    path: '/confirmation/:orderId',
    name: 'ConfirmationView',
    component: ConfirmationView,
  },
  {
    path: '/content-hub',
    name: 'ContentHubView',
    component: ContentHubView,
  },
  {
    path: '/password_reset',
    name: 'PasswordResetView',
    component: PasswordResetView,
  },
  {
    path: '/order-quote',
    name: 'OrderQuoteView',
    component: OrderQuoteView,
  },
  {
    path: '/checkin',
    name: 'CheckInView',
    component: CheckInView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundView',
    component: NotFoundView,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // check if user is not logged in
    if (!localStorage.getItem('loggedIn')) {
      // redirect to the login page
      next({ name: 'HomeView' })
    } else {
      // proceed to route
      next()
    }
  } else {
    // if no routes require auth, proceed
    next()
  }
})

export default router
